import React from "react";
import styled from "styled-components";
import { get } from "lodash";
import { useContext } from "react";
import { ThemeContext } from "styled-components";

const LogoBase = ({ logoURL, shade = "light", size = "small", ...rest }) => {
  const theme = useContext(ThemeContext);
  const { logo } = theme;
  if (!logo) {
    return <div />;
  }

  return (
    <div {...rest}>
      <a href={logoURL}>
        <img src={logo[shade]} className={`desktop ${size}`} alt="Logo" />
        <img src={logo[shade]} className={`mobile ${size}`} alt="Logo" />
      </a>
    </div>
  );
};

export const Logo = styled(LogoBase)`
  user-select: none;
  line-height: 1;
  z-index: 9;
  flex: 1 0 auto;

  img {
    display: inline-block;
    color: ${(props) => get(props, "theme.primary", "inherit")};
    max-width: unset;
    max-height: unset;
  }

  .small {
    height: ${(props) => get(props, "theme.logo.height.small", "inherit")};
  }

  .medium {
    height: ${(props) => get(props, "theme.logo.height.medium", "inherit")};
  }

  .large {
    height: ${(props) => get(props, "theme.logo.height.large", "inherit")};
  }

  .desktop {
    display: inline-block;

    @media (max-width: 40rem) {
      display: none;
    }
  }

  .mobile {
    display: none;

    @media (max-width: 40rem) {
      display: inline-block;
    }
  }
`;
