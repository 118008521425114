/**
 * LegalLinks
 *
 * Links to the Planswell Terms of Service and Privacy Policy
 */

import React from "react";
import styled from "styled-components";
import { Trans } from "@lingui/macro";

import { media } from "ui/media";

const LegalLinksBase = ({ className }) => (
  <div className={className}>
    <a
      href={process.env.REACT_APP_TERMS_OF_SERVICE_URL}
      target="_blank"
      rel="noopener noreferrer"
      tabIndex="0"
    >
      <Trans>Terms of Service</Trans>
    </a>
    <a
      href={process.env.REACT_APP_PRIVACY_POLICY_URL}
      target="_blank"
      rel="noopener noreferrer"
      tabIndex="0"
    >
      <Trans>Privacy Policy</Trans>
    </a>
  </div>
);

const LegalLinks = styled(LegalLinksBase)`
  font-size: 0.875rem;
  text-align: center;
  margin: 0 auto 10px auto;
  display: flex;

  a {
    color: ${({ theme }) => theme.footerGrey} !important;
    margin: 0.5rem;
  }

  ${media.phone} {
    font-size: 0.857rem;
    margin-bottom: 6rem;
  }

  /* Target IE10+ */
  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    a:focus {
      border: dotted 1px ${({ theme }) => theme.black};
    }
  }
`;

export default LegalLinks;
